@import
  "minima/skins/classic",
  "minima/initialize";
  
.wrapper {
    max-width: 1100px;
}

.navbar{
  li{
    display: inline-block;
    margin: 0px 5px 5px 5px;
    background-color: rgb(29, 138, 52);
    border-radius: 3px;
    padding: 5px 10px;
    a{
      color: white;
      font-weight: 400;
    }
  }
}

  